import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { useLocation } from "@reach/router"
import { useStaticQuery } from "gatsby"
import { graphql } from "gatsby"

const NavigationContainer = styled.nav`
  display: flex;
  gap: 1.906rem;
  width: 100%;
  position: relative;
  z-index: 99;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  list-style: none;

  @media (max-width: 64em) {
    padding-top: 0;
    gap: 1.25rem;
    margin-left: 1.5rem;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
`

const PositionLink = styled(Link)`
  font-family: var(--ff-secondary);
  font-style: normal;
  font-weight: 500;
  font-size: 1.125em;
  line-height: 26px;
  cursor: pointer;
  text-decoration: none;
  color: var(--clr-text-blue);

  transition: 0.1s ease-in-out;
  &:hover {
    color: var(--clr-secondary-dark);
  }

  @media (min-width: 64em) {
    font-size: 1.5em;
    line-height: 26px;
  }
`

const WorkNavBar = () => {
  const { pathname } = useLocation()

  const data = useStaticQuery(graphql`
    query {
      workMd: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/src/data/work/" } }
        sort: { fields: [frontmatter___order], order: ASC }
      ) {
        nodes {
          frontmatter {
            slug
            mainTitle
            client
            order
          }
        }
      }
    }
  `)

  const activeStyle = page => {
    console.log(pathname)
    if (pathname.includes(page)) {
      return {
        color: "var(--clr-secondary-dark)",
      }
    } else {
      return {}
    }
  }

  return (
    <NavigationContainer>
      {data.workMd.nodes.map(node => (
        <PositionLink
          key={node.frontmatter.slug}
          to={`/our-work/${node.frontmatter.slug}`}
          style={activeStyle(`/our-work/${node.frontmatter.slug}`)}
        >
          {node.frontmatter.client && `Case ${node.frontmatter.client}: `}
          {node.frontmatter.mainTitle}
        </PositionLink>
      ))}
    </NavigationContainer>
  )
}

export default WorkNavBar
