import React from "react"
import styled from "styled-components"

import Layout from "../common/Layout"
import WorkNavBar from "./WorkNavBar"
import Main from "../common/Main"
import PageIntro from "../common/intro/PageIntro"

const Container = styled.section`
  margin-inline: auto;
  margin-bottom: 6.6875rem;
  margin-inline: auto;
  max-width: min(79ch, 100% - 2.5rem);
  @media (min-width: 64em) {
    width: min(100% - 2.5rem, 1200px);
    margin-top: 3rem;
    max-width: none;
  }
`

const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    "sidenavigation"
    "jobcontent";

  @media (min-width: 64em) {
    padding-left: 2.5rem;
    grid-template-columns: 17.625rem 1fr;
    grid-template-areas: "sidenavigation jobcontent";

    width: 100%;
    background: #ffffff;
    box-shadow: rgb(122, 121, 121, 0.2) 0px 0px 0px 1px,
      0px 4px 10px rgba(0, 0, 0, 0.15);

    margin-inline: auto;
  }

  @media (min-width: 64em) {
    padding-left: 5.3125rem;
  }
`

const SideContainer = styled.aside`
  grid-area: sidenavigation;
  position: relative;
  z-index: 99;

  &:before {
    @media (max-width: 64em) {
      content: "";
      position: absolute;
      width: 4px;
      margin-left: -4px;
      height: 100%;
      background-color: var(--clr-secondary-dark);
    }
  }

  margin-top: 3rem;

  @media (min-width: 64em) {
    margin-top: 5.8125rem;
  }
`

const WorkLayout = ({ children }) => {
  const title = [["Our Work"]]

  return (
    <Layout>
      <Main>
        <PageIntro
          title={title}
          subheading="Rebase provides experienced local IT professionals for complex customer projects. We focus on private mid-size companies and public sector organisations. Below are some reference projects we have completed recently."
        />
        <Container>
          <Wrapper>
            <SideContainer>
              <WorkNavBar />
            </SideContainer>
            <div>{children}</div>
          </Wrapper>
        </Container>
      </Main>
    </Layout>
  )
}

export default WorkLayout
