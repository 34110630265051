/* eslint-disable react/jsx-no-comment-textnodes */
import React from "react"
import styled from "styled-components"
import { SubHeading } from "./WorkStyles"

const JobHeader = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 99;
  flex-direction: column;
  margin-top: 3rem;
  margin-bottom: 2rem;

  @media (min-width: 64em) {
    flex-direction: row;
    margin-top: 0;
    margin-bottom: 2rem;
  }
`
const JobInfo = styled.div``

const JobTitle = styled.h2`
  color: var(--clr-text-blue);
  font-family: var(--ff-secondary);
  font-weight: 800;
  font-size: 2.125em;
  line-height: 48px;
  letter-spacing: 0.0025em;

  margin-bottom: 1rem;

  @media (max-width: 40em) {
    font-size: 1.5em;
    line-height: 39px;
    letter-spacing: 0;
  }
`

const WorkHeader = ({ title, subheading }) => {
  return (
    <>
      <JobHeader>
        <JobInfo>
          <JobTitle>{title}</JobTitle>
          <SubHeading>{subheading}</SubHeading>
        </JobInfo>
      </JobHeader>
    </>
  )
}

export default WorkHeader
