import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { StaticImage } from "gatsby-plugin-image"
import PhoneIcon from "../../assets/svgs/phone.svg"
import EmailIcon from "../../assets/svgs/mail.svg"
import LinkedInIcon from "../../assets/svgs/linked.svg"

const StyledLink = styled.a`
  .gatsby-image-wrapper {
    height: 24px;
    width: 24px;
  }
  display: inline-block;
`

const Card = styled.div`
  display: flex;
  box-shadow: rgb(122, 121, 121, 0.2) 0px 0px 0px 1px;
  position: relative;
  width: 645px;

  margin-bottom: 2rem;

  @media (max-width: 40rem) {
    flex-direction: column;
    width: 100%;
    max-width: 400px;
  }
`

const StyledImage = styled(GatsbyImage)`
  z-index: 0;
  filter: grayscale(100%);
  image-rendering: high-quality;
  aspect-ratio: 381/430;

  img {
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
`

const Info = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-width: 264px;

  @media (max-width: 40rem) {
    width: 100%;
  }
`

const Title = styled.div`
  color: var(--clr-text-blue, #150f9d);
  font-family: var(--ff-primary);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.3125rem;
  letter-spacing: 0.00131rem;

  margin-bottom: 0.25rem;
`

const Content = styled.div`
  color: var(--clr-text-black);
  font-family: var(--ff-primary);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3125rem;
  letter-spacing: 0.00131rem;
`

const List = styled.ul`
  list-style: none;
  padding: 0;
`

const ListItem = styled.li`
  margin-bottom: 0.5rem;
`

const Name = styled.div`
  color: var(--clr-text-blue, #150f9d);

  font-family: var(--ff-primary);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.00113rem;

  margin-bottom: 0.25rem;
`

const PositionTitle = styled.div`
  color: var(--clr-text-blue, #150f9d);

  font-family: var(--ff-primary);
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.0025rem;
`

const EmployeeBox = styled.div`
  position: relative;
  display: flex;
`

const ProfileImage = styled(GatsbyImage)`
  width: 116px;
  height: 120px;
  position: absolute !important;
  right: -52px;
  bottom: -62px;
`

const PersonalLinksContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
`

const CaseCard = ({ caseData }) => {
  let image = getImage(caseData.image)
  let profileImage = getImage(caseData.profileImage)

  console.log(caseData.linkedIn)

  return (
    <Card>
      <StyledImage image={image} alt="case image" />
      <Info>
        <List>
          <ListItem>
            {caseData.client && (
              <>
                <Title>Client</Title>
                <Content>{caseData.client}</Content>
              </>
            )}
          </ListItem>
          <ListItem>
            <Title>Industry</Title>
            <Content>{caseData.industry}</Content>
          </ListItem>
          <ListItem>
            <Title>Keywords</Title>
            <Content>{caseData.keywords}</Content>
          </ListItem>
          <ListItem>
            <Title>Length</Title>
            <Content>{caseData.length}</Content>
          </ListItem>
        </List>
        <EmployeeBox>
          <List>
            <ListItem>
              <Title>Ask More</Title>
              <PersonalLinksContainer>
                <StyledLink
                  aria-label="Chat on WhatsApp"
                  href={`https://wa.me/${caseData.phone}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <StaticImage src="../../images/common/whatsapp_logo_green.png" />
                </StyledLink>
                <a href={`tel:${caseData.phone}`} title={caseData.phone}>
                  <PhoneIcon width={24} height={24} />
                </a>
                <a href={`mailto:${caseData.email}`} title={caseData.email}>
                  <EmailIcon width={24} height={24} />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={caseData.linkedIn}
                  title={caseData.linkedIn}
                >
                  <LinkedInIcon width={24} height={24} />
                </a>
              </PersonalLinksContainer>
            </ListItem>
            <ListItem>
              <Name>{caseData.employeeName}</Name>
              <PositionTitle>{caseData.positionName}</PositionTitle>
            </ListItem>
          </List>
          <ProfileImage image={profileImage} alt="rebase employee photo" />
        </EmployeeBox>
      </Info>
    </Card>
  )
}

export default CaseCard
